<template>
  <div class="pa-2">
    <label class="text--caption"
      >Note: Theme selection from Settings is applicable to <b>ALL</b> users.
      However, user preferences take precedence over it. If you would like to
      set your own theme, please visit
      <router-link to="/preferences">preferences.</router-link></label
    >
    <v-spacer class="pa-1"></v-spacer>
    <ApplicationThemes
      aut-application-themes
      mode="domain"
      :themes="colors"
      @change="requestDialog"
      @themes="updateAllowedThemes"
      :allowed="domainThemes"
    />
  </div>
</template>
<script>
import ApplicationThemes from "@/components/pageContent/ThemeControl/ApplicationThemes.vue";
import { getColorSet } from "@/store/preferences/api";
import { mapActions, mapGetters } from "vuex";
import { vuetifyUpdate } from "@/store/preferences/api.js";

const debug = require("debug")("atman.administration.settings_theme"); // eslint-disable-line

export default {
  name: "SettingsTheme",
  components: {
    ApplicationThemes,
  },
  props: {
    definition: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("preferences", ["activeTheme"]),
    ...mapGetters(["domainFeatures"]),
    domainThemes() {
      debug(`domain features`, this.domainFeatures);
      return this.domainFeatures?.allowed_themes || [];
    },
  },
  data() {
    return {
      colors: [],
    };
  },
  async mounted() {
    this.colors = await getColorSet();
  },
  methods: {
    ...mapActions(["saveThemeToDomain", "saveSettingsToDomain"]),
    updateAllowedThemes(event) {
      this.saveSettingsToDomain({
        allowed_themes: event,
      });
    },
    requestDialog(event) {
      this.saveThemeToDomain(event?.name || "");
      debug(`activeTheme`, this.activeTheme);
      vuetifyUpdate(event?.name || "");
      this.displaySuccessMessage(
        'Settings saved! Visit <a href="/preferences">preferences</a> to make changes to your theme.'
      );
    },
  },
};
</script>
